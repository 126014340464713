html, body {
  margin: 0;
  padding: 0;
}

#map {
  height: 100vh;
}

/*# sourceMappingURL=index.be191e88.css.map */
